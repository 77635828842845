import { client } from './api-models/client.gen';
import AppLayout from './components/app-layout/appLayout';
import AuthProvider from './components/app-layout/context/authProvider';

client.setConfig({
  baseUrl: `${import.meta.env.VITE_BACKEND_URL}`,
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
  throwOnError: true,
});

client.interceptors.response.use((response) => {
  if (!response) {
    // If an error is unknown to us or is an error not thrown by the backend
    throw new Error('No response');
  }
  return response;
});

function App() {
  return (
    <AuthProvider>
      <AppLayout />
    </AuthProvider>
  );
}

export default App;
