import { ImpactThemeInterface, Statistic } from './global';

export default class ImpactTheme implements ImpactThemeInterface {
  headline_statistics: Statistic[] = [];

  graph_details = {
    categorical_label: '',
    value_label: '',
    data: [],
    title: '',
  };
}
