import {
  VictoryAxis, VictoryBar, VictoryChart, VictoryLabel,
} from 'victory';
import { formatSnapshot } from '../../utils/formatters';
import { CategoricalData } from '../../types/global';

export default function BarGraph({
  graphData, title, categoricalLabel, valueLabel,
}: { graphData: CategoricalData[], title:string, categoricalLabel:string, valueLabel :string }) {
  if (graphData.length === 0) {
    return <div>No data available</div>;
  }

  const mappedData = graphData.map((d) => (
    {
      x: formatSnapshot(d.group.toString(), 'short'),
      y: d.value,
    }
  ));
  const [xKey, yKey] = Object.keys(mappedData[0]);
  const yValues = mappedData.map((d) => d.x);
  const yTicks = yValues.map((y) => y);

  return (
    <div className="w-full m-2">
      <VictoryChart
        domain={{ x: [1, mappedData.length + 1] }}
        domainPadding={20}
      >
        <VictoryLabel
          text={title}
          style={{
            fontSize: 14,
          }}
          dx={28}
          dy={18}
        />
        <VictoryAxis
    // tickValues specifies both the number of ticks and where
    // they are placed on the axis
          tickValues={yValues}
          tickFormat={yTicks}
          tickLabelComponent={<VictoryLabel style={{ fontSize: 14 }} angle={-15} />}
        />

        <VictoryAxis
          dependentAxis
          label={valueLabel}
          style={{
            axis: { stroke: 'transparent' },
            axisLabel: {
              fontSize: 14,
              padding: 35,
            },
            tickLabels: {
              fontSize: 12,
            },

          }}
    // tickFormat specifies how ticks should be displayed
          tickFormat={(x:number) => {
            if (x >= 1000) {
              const value = x / 1000;
              return Number.isInteger(value) ? `${value}K` : `${value.toFixed(1)}K`;
            }
            return Math.round(x).toString();
          }}
        />
        <VictoryLabel text={categoricalLabel} dy={290} dx={160} style={{ fontSize: 14 }} />
        <VictoryBar
          style={{ data: { fill: '#10623b' } }}
          data={mappedData}
          barWidth={40}
          x={xKey}
          y={yKey}
          labels={({ datum }) => datum.y}

        />
      </VictoryChart>
    </div>
  );
}
