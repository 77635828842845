import { ReactNode, useState } from 'react';
import ErrorFallback from '../components/errorFallback';
import LoadingFallback from '../components/loadingFallback';

export default function useRequestState<TParams extends any[], TReturn>() {
  const [data, setData] = useState<TReturn | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleRequest = async (
    asyncFunction: (...args: TParams) => Promise<TReturn>,
    ...args: TParams
  ) => {
    try {
      setLoading(true);
      setError(null);

      const response = await asyncFunction(...args);

      if (response !== undefined && response !== null) {
        setData(response);
      }

      return response;
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
      throw err;
    } finally {
      setLoading(false);
    }
  };
  function FallbackWrapper({
    children,
    loadingMessage,
  }: {
    children: ReactNode;
    loadingMessage: string;
  }) {
    return (
      <>
        {loading && <LoadingFallback message={loadingMessage} />}
        {!loading && error ? <ErrorFallback errorMessage={error} /> : ''}
        {!loading && !error ? children : ''}
      </>
    );
  }

  return {
    data,
    loading,
    error,
    handleRequest,
    FallbackWrapper,
  };
}
