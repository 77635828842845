import { Form, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ExportCSV from '../exportCsv';
import BarGraph from './barGraph';
import FallbackGraph from './fallbackGraph';
import SmallButton from '../smallButton';
import MultiCheckboxSelect from './multiCheckboxSelect';
import Api from '../../services/api';
import { Theme } from '../../types/global';
import Subtext from './subtext';
import HeadlineCard from './headlineCard';
import useMultiCheckboxDropdownStore from './MultiCheckboxDropdownStore';
import FormSummary from './formSummary';
import SplitLayout from '../splitLayout';
import { adminAccess } from '../../utils/authRoleWrap';
import useToastStore from '../app-layout/toastStore';
import { snakeCase } from '../../utils/formatters';
import ImpactTheme from '../../types/impactTheme';

export default function ImpactCard({ theme }: { theme:Theme }) {
  const navigate = useNavigate();
  const selected = useMultiCheckboxDropdownStore((state) => state.selected);
  const [graphData, setGraphData] = useState([]);
  const [themeData, setThemeData] = useState({});
  const [impactTheme, setImpactTheme] = useState(new ImpactTheme());
  const showToast = useToastStore((state) => state.showToast);

  const { title, filterOptions, socialProof } = theme;

  useEffect(() => {
    const getImpactData = async (): Promise<void> => {
      try {
        const response: ImpactTheme = await Api.post('/api/impact-theme-data', {
          impact_theme_title: title,
        });

        setImpactTheme(response);
        setGraphData(response.graph_details.data);
      } catch (error) {
        throw new Error(`Error fetching data for ${title} card`);
      }
    };

    if (!title) return;
    const themeTitle = snakeCase(title);
    getImpactData();
    if (selected[themeTitle]) {
      setThemeData(selected[themeTitle]);
    }
  }, [selected, themeData, title, filterOptions]);

  const sendDataToCalculator = async (filteredData: any) => {
    try {
      const response = await Api.post('/api/households-to-calculator', filteredData);
      showToast(response, 'success');
    } catch (error) {
      showToast(error instanceof Error ? error.message : 'An error occurred', 'error');
    }
  };
  const createFilteredCampaign = (themeTitle:string) => {
    const cleanImpactTheme = snakeCase(themeTitle);
    return navigate(`/campaigns/${cleanImpactTheme}/new`);
  };

  const AdminActionButton = adminAccess(SmallButton);

  return (
    <div className="w-full">
      <div id="impact-card" className="h-max hover:shadow-sideShadow rounded-sm m-2 p-2 w-full">
        <div id="impact-card-header" className="flex flex-col md:flex-row justify-between">
          <div className="flex flex-col m-2">
            <p className="font-thin text-xs"> definition of impact theme</p>
          </div>
          <div className="flex flex-row justify-around gap-2 text-sm">
            <AdminActionButton text="Send to Calculator" isGreen={false} onClick={() => sendDataToCalculator(themeData)} />
            <AdminActionButton text="Create Campaign" isGreen={false} onClick={() => createFilteredCampaign(title)} />
            <ExportCSV id={theme.title} data={selected} icon={false} />
          </div>
        </div>
        <SplitLayout rightWeight="w-1/2" leftWeight="w-1/2">
          <div id="impact-card-left-side" className="flex flex-col justify-between">
            <div id="impact-card-headline-container" className="m-2 h-fit flex flex-col md:flex-row justify-evenly">
              {impactTheme.headline_statistics ? (
                impactTheme.headline_statistics.map((headline) => (
                  <HeadlineCard
                    key={headline.title}
                    text={headline.title}
                    displayNumber={headline.display_number}
                    variance={headline.display_variance}
                  />
                ))

              ) : ''}
            </div>
            {impactTheme.graph_details ? <BarGraph graphData={graphData} title="" valueLabel={impactTheme.graph_details.value_label} categoricalLabel={impactTheme.graph_details.categorical_label} /> : <FallbackGraph />}
            <Subtext text={socialProof.text} link={socialProof.link} />
          </div>
          <div id="impact-card-right-side" className="flex flex-col md:flex-row justify-between w-full">
            <div className="w-9/12">
              <Form className="">
                {filterOptions.map(({ label, options }) => (
                  <MultiCheckboxSelect mutliSelectOptions={options} key={`${title}-${label}`} label={label} title={title} />
                ))}
              </Form>
            </div>
            <div className="flex flex-col lg:flex-row">
              <FormSummary object={themeData} />
            </div>
          </div>
        </SplitLayout>
      </div>
    </div>
  );
}
