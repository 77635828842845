import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import customIcons from '../utils/icons';

export default function ErrorPage() {
  const error = useRouteError();
  let errorMessage: string;

  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    errorMessage = error.data.message || error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    errorMessage = 'Unknown error';
  }

  return (
    <div id="error-page" className="flex flex-col m-auto text-center items-center w-full p-4 ">
      <span className="text-2xl m-4 text-pipstatus-alert">{customIcons.errorIcon}</span>
      <p>{errorMessage}</p>
    </div>
  );
}
